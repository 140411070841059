import React from 'react';
import { StyledLoadingCurtainBlock } from '@/styled/common/CommonStyled';

const LoadingCurtainBlock = () => {
  return (
    <StyledLoadingCurtainBlock>
    </StyledLoadingCurtainBlock>
  )
}

export default LoadingCurtainBlock;